<template>
  <material-chart-card
    id="monthlyResultsChart"
    :class="{'admin':!$auth.user().client}"
    v-bind="$attrs"
    :data="lab_results_monthly"
    :options="options"
    :loading="loading.lab_results_monthly && !lab_results_monthly.labels.length"
    :responsive-options="responsiveOptions"
    color="indigo darken-1"
    type="Line"
  >
    <h4 class="text-h6 font-weight-light">
      {{ $t('charts.monthly_lab_result_volume') }}
    </h4>
    <div
      v-if="lab_results_monthly.labels.length && !hideTrend"
      class="category d-inline-flex font-weight-light"
    >
      <v-icon
        :color="monthlyChange.val > 0 ? 'green' : 'red'"
        small
      >
        {{ monthlyChange.val > 0 ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
      </v-icon>
      <span
        v-if="lab_results_monthly"
        :class="monthlyChange.val > 0 ? 'green--text' : 'red--text'"
        class="mr-1"
      >
        {{ monthlyChange.val | percentage }}
      </span>
      &nbsp; {{ monthlyChange.text }}
    </div>

    <template #actions>
      <v-icon
        class="mr-2"
        small
      >
        mdi-clock-outline
      </v-icon>
      <span class="text-caption grey--text font-weight-light">{{ $t('updated') }} {{ updated | duration('humanize', true) }}</span>
      <v-spacer />
      <card-reload
        :loading="loading.lab_results_monthly"
        @reload="fetchMonthlyResults(true)"
      />
    </template>
  </material-chart-card>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  components: {
    CardReload: () => import('@/components/CardReload.vue'),
  },
  props: {
    hideTrend: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      options: {
        // lineSmooth: Vue.chartist.Interpolation.cardinal({
        //   tension: 5,
        // }),
        showArea: true,
        showPoint: false,
        fullWidth: true,
        chartPadding: {
          top: 20,
          right: 20,
          bottom: 0,
          left: this.$auth.check({ stats: 'performance' }) ? 6 : -20,
        },
        axisX: {
          labelOffset: {
            x: -10,
            y: 3,
          },
        },
        axisY: {
          showLabel: this.$auth.check({ stats: 'performance' }),
          labelOffset: {
            y: 5,
          },
          labelInterpolationFnc: (value) => value >= 1000 ? Vue.options.filters.number(value, '0.0a') : value,
        },
      },
      responsiveOptions: [
        ['screen and (max-width: 640px)', {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: (value) => value[0],
          },
        }],
      ],
    }
  },
  computed: {
    ...mapState('charts', ['loading', 'lab_results_monthly']),
    updated () {
      return this.$moment(this.lab_results_monthly.time).diff()
    },
    monthlyChange () {
      if (this.lab_results_monthly.trend) {
        return {
          val: (this.lab_results_monthly.trend.last_period - this.lab_results_monthly.trend.prev_period) / this.lab_results_monthly.trend.prev_period,
          text: 'compared to the previous 30 day period',
        }
      } else {
        return {
          val: (this.lab_results_monthly.series[0][this.lab_results_monthly.series[0]?.length - 1] - this.lab_results_monthly.series[0][this.lab_results_monthly.series[0]?.length - 2]) / this.lab_results_monthly.series[0][this.lab_results_monthly.series[0]?.length - 2],
          text: 'compared to last month',
        }
      }
    },
  },
  created () {
    if (!this.lab_results_monthly.labels.length || this.$moment(this.lab_results_monthly.time).diff(this.$moment().subtract(1, 'hour')) < 0) {
      this.fetchMonthlyResults()
    }
  },
  methods: {
    ...mapMutations("charts", ["setMonthlyChartData"]),
    ...mapActions("charts", ["fetchMonthlyResults"]),
  },
}
</script>

<style lang="scss">
  #monthlyResultsChart {
    .ct-point {
      stroke-width: 4px !important;
    }
    .ct-line {
        stroke-width: 2px;
    }
  }
  #monthlyResultsChart.admin {
    .ct-series-a {
      .ct-line, .ct-point {
      /* Set the colour of this series line */
        stroke: rgb(245, 152, 152);
        /* Control the thikness of your lines */
        stroke-width: 2px;
        /* Create a dashed line with a pattern */
        // stroke-dasharray: 10px 2px;
      }
      .ct-area {
        fill: rgb(245, 152, 152);
      }
    }
    .ct-series-b {
      .ct-line, .ct-point {
      /* Set the colour of this series line */
        stroke: rgb(132, 243, 132);
        /* Control the thikness of your lines */
        stroke-width: 3px;
      }
      .ct-area {
        fill: rgb(132, 243, 132);
      }
    }
    .ct-series-c {
      .ct-line, .ct-point {
      /* Set the colour of this series line */
        stroke: rgb(132, 243, 132);
        /* Control the thikness of your lines */
        stroke-width: 2px;
        stroke-dasharray: 2;
      }
      .ct-area {
        fill: none;
      }
    }
  }
</style>
